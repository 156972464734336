import React, { PureComponent } from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import classnames from 'classnames'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/PageHeader'

import { createRichTextSerializer } from '../utils/prismic'

import champions from '../images/network-champions.png'
import innovators from '../images/network-innovators.png'
import stakeholders from '../images/network-stakeholders.png'
import { withPreview } from 'gatsby-source-prismic-graphql'

const query = graphql`
  query {
    prismic {
      page: longevity_network_page(uid: "longevity-network", lang: "en-us") {
        title
        description
        column_1_header
        column_2_header
        column_3_header
        column_1_text
        column_2_text
        column_3_text
      }
    }
  }
`

export default class NetworkPage extends PureComponent {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={withPreview(data => {
          const { page } = data.prismic
          return (
            <Layout>
              <SEO title={page.title} keywords={[`longevity`]} />
              <PageHeader
                titles={[page.title]}
                richDescription={page.description}
              />

              <div className="max-w-5xl mx-auto">
                <div className="lg:flex justify-between font-serif text-blue-dianne my-32">
                  <div className="lg:w-1/3 lg:mr-8 mb-12 lg:mb-0 relative">
                    <div
                      className="bg-selago px-8 lg:px-12 pt-16 pb-32 bg-cover bg-right"
                      style={{ backgroundImage: `url(${champions})` }}
                    >
                      <h2
                        className="text-2xl font-normal"
                        ref={node => (this.headerNode0 = node)}
                      >
                        {page.column_1_header.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index <
                              page.column_1_header.split('\n').length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                      </h2>
                    </div>
                    <div className="px-8 lg:px-12 -mt-16">
                      <div className="bg-white p-8">
                        <p
                          className="font-sans leading-normal"
                          ref={node => (this.descriptionNode0 = node)}
                        >
                          {page.column_1_text.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index <
                                page.column_1_text.split('\n').length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="lg:w-1/3 lg:mr-8 mb-12 lg:mb-0 relative">
                    <div
                      className="bg-selago px-8 lg:px-12 pt-16 pb-32 bg-cover bg-right"
                      style={{ backgroundImage: `url(${innovators})` }}
                    >
                      <h2
                        className="text-2xl font-normal"
                        ref={node => (this.headerNode1 = node)}
                      >
                        
                          {page.column_2_header
                            .split('\n')
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                {index <
                                  page.column_2_header.split('\n').length -
                                    1 && <br />}
                              </React.Fragment>
                            ))}
                      </h2>
                    </div>
                    <div className="px-8 lg:px-12 -mt-16">
                      <div className="bg-white p-8">
                        <p
                          className="font-sans leading-normal"
                          ref={node => (this.descriptionNode1 = node)}
                        >
                          {page.column_2_text.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index <
                                page.column_2_text.split('\n').length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                                
                  <div className="lg:w-1/3 relative">
                    <div
                      className="bg-selago px-8 lg:px-12 pt-16 pb-32 bg-cover bg-right"
                      style={{ backgroundImage: `url(${stakeholders})` }}
                    >
                      <h2
                        className="text-2xl font-normal"
                        ref={node => (this.headerNode2 = node)}
                      >
                        {page.column_3_header.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index <
                              page.column_3_header.split('\n').length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                      </h2>
                    </div>
                    <div className="px-8 lg:px-12 -mt-16">
                      <div className="bg-white p-8">
                        <p
                          className="font-sans leading-normal"
                          ref={node => (this.descriptionNode2 = node)}
                        >
                          {page.column_3_text.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index <
                                page.column_3_text.split('\n').length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          )
        }, query)}
      />
    )
  }
}
